<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import BrandServiceApi from "@/service/api/brands";
import CategoriesServiceApi from "@/service/api/categories";
import Switches from "vue-switches";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */

import { required } from "vuelidate/lib/validators";

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

const only_image = (val) => {
  if (val) {
    if (val && val.files && val.files[0]) {
      let fileType = val.files[0].file.type;
      const allowedType = ["image/jpeg", "image/png", "image/jpg"];
      return allowedType.includes(fileType);
    } else if (val) {
      return validURL(val);
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export default {
  page: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, Switches },
  data() {
    return {
      tableData: [],
      title: "Brands",
      items: [
        {
          text: "Tables",
          href: "/",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      editForm: {
        name_ar: "",
        name_en: "",
        desc_en: "",
        desc_ar: "",
        status: true,
        category_id: "",
        image: null,
        id: null,
      },
      editFormReset: {
        name_ar: "",
        name_en: "",
        desc_en: "",
        desc_ar: "",
        status: true,
        category_id: "",
        image: null,
        id: null,
      },
      brandImage: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [10, 15, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      submitted: false,
      categories: null,
      brandDelete: null,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name_en", sortable: true, label: this.$t("brands.table.name") },
        {
          key: "desc_en",
          sortable: true,
          label: this.$t("brands.table.description"),
        },
        {
          key: "status",
          sortable: true,
          label: this.$t("brands.table.status"),
        },
        {
          key: "category_id",
          sortable: true,
          label: this.$t("brands.table.category"),
        },
        { key: "action", label: this.$t("brands.table.action") },
      ],
      selectedBrand: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    categoryOptions: function() {
      if (this.categories) {
        return this.categories.map((val) => {
          const options = {
            label: val.name_en,
            value: val.id,
          };
          return options;
        });
      } else {
        return this.categories;
      }
    },
  },
  mounted() {
    CategoriesServiceApi.getAllMainCategories().then((response) => {
      this.categories = response.data;
      BrandServiceApi.getAllBrands().then((response) => {
        this.tableData = response.data;
      });
    });
    this.totalRows = this.items.length;
  },
  validations: {
    editForm: {
      name_ar: { required },
      name_en: { required },
      desc_en: { required },
      desc_ar: { required },
      status: { required },
      category_id: { required },
      image: { only_image, required },
    },
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectBrand(item) {
      this.selectedBrand = { ...item };
      this.brandImage = this.selectedBrand.image;
      this.editForm = { ...this.selectedBrand };
      console.log(this.editForm);
    },
    deleteBrand() {
      if (this.brandDelete) {
        let formData = new FormData();
        formData.append("action", "delete");
        formData.append("brand_id", this.brandDelete);
        BrandServiceApi.brandAction(formData)
          .then((response) => {
            console.log(response);
            this.updateBrandList(response, "delete");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    addBrand() {
      this.resetForm();
    },
    updateBrandList(item, action) {
      if (action === "add") {
        if (item && item.data && item.data.brand) {
          this.tableData.push(item.data.brand);
          this.$refs["brand"].hide();
          this.resetForm();
        }
      } else if (action === "update") {
        if (item && item.data && item.data.brand) {
          const removeItem = item.data.brand.id;
          this.tableData = this.tableData.filter(
            (item) => item.id !== removeItem
          );
          this.tableData.push(item.data.brand);
          this.$refs["brand"].hide();
          this.resetForm();
        }
      } else {
        if (item && item.data && item.data.brand[0]) {
          const removeItem = item.data.brand[0].id;
          this.tableData = this.tableData.filter(
            (item) => item.id !== removeItem
          );
          this.$refs["brandDelete"].hide();
          this.brandDelete = null;
        }
      }
    },
    addFormSubmit() {
      this.submitted = true;
      this.$v.$touch();
      console.log("adding..");
      if (this.$v.editForm.$invalid) {
        return;
      } else {
        let formData = new FormData();
        formData.append("action", "add");
        formData.append(
          "image_path",
          this.editForm.image ? this.editForm.image.files[0].file : null
        );
        formData.append("name_en", this.editForm.name_ar);
        formData.append("name_ar", this.editForm.name_en);
        formData.append("desc_en", this.editForm.desc_en);
        formData.append("desc_ar", this.editForm.desc_ar);
        formData.append("category_id", this.editForm.category_id);
        formData.append("status", this.editForm.status);

        BrandServiceApi.brandAction(formData)
          .then((response) => {
            console.log(response);
            this.updateBrandList(response, "add");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    updateFormSubmit() {
      this.submitted = true;
      this.$v.$touch();

      let {
        category_id: category_id,
        desc_ar: desc_ar,
        desc_en: desc_en,
        name_ar: name_ar,
        name_en: name_en,
        status: status,
        image: image,
      } = this.$v.editForm;
      console.log(image.only_image);
      if (
        category_id.$invalid &&
        desc_ar.$invalid &&
        desc_en.$invalid &&
        name_ar.$invalid &&
        name_en.$invalid &&
        status.$invalid
      ) {
        return;
      } else {
        if (image.only_image) {
          console.log("Updating...");
          let formData = new FormData();
          formData.append("action", "edit");
          if (this.editForm.image && this.editForm.image.files) {
            formData.append("image_path", this.editForm.image.files[0].file);
          }

          formData.append("brand_id", this.editForm.id);
          formData.append("name_en", this.editForm.name_ar);
          formData.append("name_ar", this.editForm.name_en);
          formData.append("desc_en", this.editForm.desc_en);
          formData.append("desc_ar", this.editForm.desc_ar);
          formData.append("category_id", this.editForm.category);
          formData.append("status", this.editForm.status);

          BrandServiceApi.brandAction(formData)
            .then((response) => {
              console.log(response);
              this.updateBrandList(response, "update");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    resetForm() {
      this.submitted = false;
      this.editForm = { ...this.editFormReset };
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-dropdown
              right
              toggle-class="arrow-none card-drop"
              class="float-right"
              variant="white"
            >
              <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical"></i>
              </template>
              <b-dropdown-item v-b-modal.brand v-on:click="addBrand()"
                >Add Brand</b-dropdown-item
              >
              <b-dropdown-item>---</b-dropdown-item>
            </b-dropdown>
            <div class="row mt-4" style="padding-top:10px;">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(name_en)="row">
                  <div style="display: flex; flex-wrap: wrap;">
                    <div style="flex: 30%;">
                      <img :src="row.item.image" width="32" alt="" />
                    </div>
                    <div style="flex: 70%;text-align:left">
                      <div>{{ row.value }}</div>
                      <div>{{ row.item.name_ar }}</div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(desc_en)="row">
                  <div>{{ row.value }}</div>
                  <div>{{ row.item.desc_ar }}</div>
                </template>
                <template v-slot:cell(category_id)="row">
                  <div v-if="row.item.category_id !== null">
                    {{ categories[row.item.category_id - 1].name_en }}
                  </div>
                </template>
                <template v-slot:cell(action)="row">
                  <a
                    v-b-modal.brand
                    v-on:click="selectBrand(row.item)"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    data-toggle="tooltip"
                    title="Edit"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a
                    v-b-modal.brand-delete
                    v-on:click="() => (brandDelete = row.item.id)"
                    class="text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                  >
                    <i class="mdi mdi-trash-can font-size-18"></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="brandDelete"
      id="brand-delete"
      centered
      title="Delete Brand"
      hide-footer
    >
      <p>
        Delete
      </p>
      <div class="modal-footer">
        <button
          type="button"
          v-on:click="deleteBrand()"
          class="btn btn-primary"
        >
          Please confirm before delete
        </button>
        <button
          type="button"
          @click="$bvModal.hide('brand-delete')"
          class="btn btn-secondary"
        >
          Close
        </button>
      </div>
    </b-modal>
    <b-modal
      id="brand"
      ref="brand"
      centered
      title="Edit Brand"
      title-class="font-16"
      size="lg"
      hide-footer
    >
      <div class="card">
        <div class="card-body">
          <form class="needs-validation" @submit.prevent>
            <div class="row" v-if="editForm.id">
              <div class="col-lg-4">
                <img
                  v-if="brandImage"
                  :src="brandImage"
                  srcset=""
                  width="200"
                  style="display:block; 
                         box-shadow: 0 1px 8px 0 rgba(0,0,0,0.2);
                        transition: 0.3s;
                        border-radius:30px;
                        margin-bottom:40px;
                        padding:12px;"
                />
                <div
                  v-else
                  style="background-color: #f1f1f1;
                                height: 200px;
                                margin-bottom:40px;
                                border-radius: 30px;"
                >
                  <div style="margin:auto;width:62px;line-height:200px;">
                    No image
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="form-group">
                  <label for="validationCustom01">Name English</label>
                  <input
                    id="validationCustom01"
                    v-model="editForm.name_en"
                    type="text"
                    class="form-control"
                    placeholder="Name English"
                    value="Mark"
                    :class="{
                      'is-invalid': submitted && $v.editForm.name_en.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.editForm.name_en.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.editForm.name_en.required"
                      >Name is required.</span
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label for="validationCustom02">Name Arabic</label>
                  <input
                    id="name_ar"
                    v-model="editForm.name_ar"
                    type="text"
                    class="form-control"
                    placeholder="Name Arabic"
                    value="Otto"
                    :class="{
                      'is-invalid': submitted && $v.editForm.name_ar.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.editForm.name_ar.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.editForm.name_ar.required"
                      >Arabic name is required.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="validationCustom01">Name English</label>
                  <input
                    id="validationCustom01"
                    v-model="editForm.name_en"
                    type="text"
                    class="form-control"
                    placeholder="Name English"
                    value="Mark"
                    :class="{
                      'is-invalid': submitted && $v.editForm.name_en.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.editForm.name_en.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.editForm.name_en.required"
                      >Name is required.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="validationCustom02">Name Arabic</label>
                  <input
                    id="name_ar"
                    v-model="editForm.name_ar"
                    type="text"
                    class="form-control"
                    placeholder="Name Arabic"
                    value="Otto"
                    :class="{
                      'is-invalid': submitted && $v.editForm.name_ar.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.editForm.name_ar.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.editForm.name_ar.required"
                      >Arabic name is required.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <FormulateInput
                    v-if="editForm.id"
                    type="image"
                    name="Brand images"
                    v-model="editForm.image"
                    label="Select an image to upload"
                    help="Select a png, jpg or jpeg to upload."
                    :error="
                      submitted && !$v.editForm.image.only_image
                        ? 'Please select a png, jpg or jpeg to upload'
                        : null
                    "
                  />
                  <FormulateInput
                    v-else
                    type="image"
                    name="Brand images"
                    v-model="editForm.image"
                    label="Select an image to upload"
                    help="Select a png, jpg or jpeg to upload."
                    :error="
                      submitted && $v.editForm.image.$invalid
                        ? 'Please select a png, jpg or jpeg to upload'
                        : null
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="validationCustom03">Descriptions English</label>
                  <input
                    v-model="editForm.desc_en"
                    type="text"
                    class="form-control"
                    placeholder="Descriptions English"
                    :class="{
                      'is-invalid': submitted && $v.editForm.desc_en.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.editForm.desc_en.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.editForm.desc_en.required"
                      >English description is required.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="validationCustom03">Descriptions Arabic</label>
                  <input
                    v-model="editForm.desc_ar"
                    type="text"
                    class="form-control"
                    placeholder="Descriptions Arabic"
                    :class="{
                      'is-invalid': submitted && $v.editForm.desc_ar.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.editForm.desc_ar.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.editForm.desc_ar.required"
                      >Arabic description is required.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <p style="font-weight:600">Status</p>
                  <div class="row">
                    <div class="col-4">
                      <span style="position: relative;top: -6px;left: 7px;">
                        Active
                      </span>
                    </div>
                    <div class="col-8">
                      <switches
                        v-model="editForm.status"
                        type-bold="false"
                        color="danger"
                        class="ml-1 mb-0"
                      ></switches>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="validationCustom03">Category</label>
                  <FormulateInput
                    type="select"
                    v-model="editForm.category_id"
                    :options="categoryOptions"
                    placeholder="Choose a category"
                    :error="
                      submitted && $v.editForm.category_id.$error
                        ? 'Please select a category.'
                        : null
                    "
                  />
                </div>
              </div>
            </div>
            <div class="mt-4 text-center m-2">
              <button
                v-if="editForm.id"
                class="btn btn-primary w-md waves-effect waves-light float-left"
                type="submit"
                v-on:click="updateFormSubmit()"
              >
                Update
              </button>
              <button
                v-else
                class="btn btn-primary w-md waves-effect waves-light float-left"
                type="button"
                v-on:click="addFormSubmit()"
              >
                Add
              </button>
              <button
                class="btn btn-primary w-md waves-effect waves-light float-right"
                type="button"
                v-on:click="
                  resetForm();
                  $bvModal.hide('brand');
                "
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
