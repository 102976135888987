import axios from 'axios'
//import authHeader from '../auth-header'
import ConfigService from "../config"
import authHeaderMultipart from "../auth-header-multipart" 

const API_URL = ConfigService.base_url;

class BrandServiceApi {
    getAllBrands() {
        return axios
          .get(API_URL + 'brands')
          .then(response => {
            console.log(response.data);
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      brandAction(brand){
        return axios
        .post(API_URL + 'brand', brand,
        {
          headers: authHeaderMultipart()
        })
        .then(response => {
          return response;
        })
        .catch(error=> {
            return Promise.reject(error);
          }
        )
      }
}



export default new BrandServiceApi()